:root {
  --primary-color1: #ffc17d;
  --primary-color2: #c09260;
  --secondary-color1: #a89a8b;
  --secondary-color2: #000000;
  --secondary-color3: #ffffff;
  --bg-color1: #ffffff;
  --bg-color2: #f9f9f9;
  --bg-color3: #000000;
  --button-color1: #c09260;
  --button-color2: #444444;
  --border-color1: #222222;
  --border-color2: #dddddd;
  --Aaca298: #aca298;
  --Affdab2: #ffdab2;
  --Ae4caae: #e4caae;
  --A4e3d2a: #4e3d2a;
  --Af8f8f8: #f8f8f8;
  --A111111: #111111;
  --A101011: #101011;
  --A1d1d1d: #1d1d1d;
  --Acccccc: #cccccc;
  --Adba366: #dba366;
  --A604a32: #604a32;
  --Aff4b4b: #ff4b4b;
}
